body {
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

/* Then style the iframe to fit in the container div with full height and width */
iframe {
   position: relative;
   margin: auto;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 250px;
   border-radius: 9px;
   border: 2px solid transparent;
   text-align: center;
   scroll-behavior: smooth;
}

img {
   transition: transform 0.68s ease-in-out;
}



/*img:hover {
    transform: rotate(15deg);
    transition: transform 0.33s ease-in-out;
}*/
.App {
   overflow-y: hidden;
   overflow-x: hidden;
}

.about-img {
   height: 100%;
   width: 100%;
}

.banner {
   height: 200px;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}

.cardslider {
   padding-left: 10%;
   padding-right: 10%;
   width: 100%;
}

.ce_lab_logo {
   height: 100%;
   width: 100%;
}

.container {
   position: relative;
   overflow: hidden;
}

.slide-container {
   position: relative;
   overflow: hidden;
   margin-bottom: -30%;
   margin-top: -18%;
}

.display-block {
   display: block;
}

.display-none {
   display: none;
}

.download-button {
   position: relative;
   display: inline-block;
   background-color: transparent;
   outline: transparent;
   border-color: transparent;
   border-radius: 7px;
   border: 3px solid transparent;
   padding-left: 3px;
   padding-right: 3px;
}

.download-button:hover {
   background-color: rgb(202, 208, 224);
}

/* Tooltip text */
.download-button .tooltiptext {
   visibility: hidden;
   width: 180px;
   background-color: black;
   color: #fff;
   text-align: center;
   border-radius: 6px;
   padding: 5px 0;

   /* Position the tooltip */
   position: absolute;
   z-index: 1;
   font-size: medium;
   top: -130%;
   margin-left: -102px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.download-button:hover .tooltiptext {
   visibility: visible;
}

.download-iframe {
   display: none;
}
.download-select{
   background-color: gray;
}
.each-card {
   border-color: rgb(228, 235, 253);
   border: 3px solid rgb(228, 235, 253);
   border-radius: 26px;
   text-align: center;
   width: 80%;
   height: 80%;
   margin-left: 10%;
   margin-right: 10%;
   margin-bottom: 8%;
   margin-top: 8%;
}

.each-inner-card {
   text-align: center;
   width: 80%;
   height: 80%;
   margin: 10%;
}

.each-slide {
   text-align: center;
   width: 100%;
   position: center;
   padding-left: 10%;
   height: 100%;
}

.email_link {
   margin: auto;
   width: 50%;
   border: 3px solid green;
   padding: 10px;
}

.freepik-link {
   display: none;
}

*:focus {
   outline: 0 !important;
}

.footer {
   height: 100%;
   width: 100%;
   padding-top: 0.5%;
   padding-bottom: 3%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   color: white;
   background-color: rgb(25, 70, 136);
}

.footer-button {
   background-color: transparent;
   outline: transparent;
   border-color: transparent;
   border-radius: 7px;
   border: 3px solid transparent;
   padding-left: 5px;
   padding-right: 5px;
   padding-bottom: 5px;
   margin-left: 10px;
   margin-right: 10px;
}

.footer-button:hover {
   background-color: rgb(202, 208, 224);
   outline: transparent;
   border-color: transparent;
   border-radius: 7px;
   border: 3px solid transparent;
   padding-left: 5px;
   padding-right: 5px;
   padding-bottom: 5px;
   margin-left: 10px;
   margin-right: 10px;
}

.footer-button-text {
   font-size: 88%;
   height: 36px;
   width: 200px;
   display: inline-block;
   background-color: transparent;
   outline: transparent;
   border-color: transparent;
   border-radius: 7px;
   border: 3px solid transparent;
   font-weight: 500;
   margin: 5px;
   text-align: center;
   position: relative;
   padding-left: 5px;
   padding-right: 5px;
   padding-top: 4px;
}

.footer-button-text:hover {
   background-color: rgb(202, 208, 224);
}

.footer-button-text .tooltiptext {
   visibility: hidden;
   width: 180px;
   background-color: black;
   color: #fff;
   text-align: center;
   border-radius: 6px;
   padding: 5px 0;
   top: 0px;
   left: 110px;
   position: absolute;
   z-index: 1;
   font-size: medium;
   font-weight: 400;
   top: -130%;
   margin-left: -102px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.footer-button-text:hover .tooltiptext {
   visibility: visible;
}

.footer-container {
   position: relative;
   overflow: hidden;
   width: 80%;
   height: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
   scroll-padding-top: auto;
   margin-left: 10%;
}

.footer-link-button {
   color: rgb(255, 0, 0);
   background-color: transparent;
   outline: transparent;
   border-color: transparent;
   border-radius: 6;
   border: 1px solid transparent;
   padding: 0%;
   margin: 0%;
}

.footer-link-button:hover {
   color: rgb(25, 70, 136);
   background-color: transparent;
   outline: transparent;
   border-color: transparent;
   border-radius: 6;
   border: 1px solid transparent;
}

.header {
   height: 80px;
}

.heading_container {
   padding-top: 10%;
   padding-left: 11%;
   color: rgb(255, 255, 255);
}

.logo-paragraph {
   padding-left: 6.6%;
}

.mainLogo {
   height: 55px;
   width: 150px;
   position: relative;
}

.modal {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.6);
}

.modal-main {
   padding: 5%;
   position: fixed;
   background: #ffffff;
   border-radius: 3px;
   border: 2px solid rgb(25, 70, 136);
   width: 100%;
   height: 100%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: calc(100vh - 210px);
   overflow-y: auto;
   overflow-x: hidden;
}

.NavBar {
   position: center;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}

.navbar-button {
   color: white;
   background-color: transparent;
   outline: transparent;
   border-color: transparent;
   border-radius: 7px;
   border: 1px solid transparent;
   padding: 4px;
   margin: 10px;
   font-weight: 500;
}

.navbar-button:hover {
   color: red;
}

.navbar-button-ls {
   color: white;
   background-color: transparent;
   outline: transparent;
   border-color: transparent;
   border-radius: 7px;
   border: 1px solid transparent;
   margin: 10px;
   size: auto;
}

.navbar-button-ls:hover {
   color: red;
}

.nav__dot {
   display: none;
}

.nav__dot {
   display: block;
   position: fixed;
   top: calc(50% - 125px);
   right: 4%;
   z-index: 3;
}

.nav__dot li {
   width: 15px;
   height: 15px;
   margin: 0 0 30px;
   background: rgba(202, 208, 224, 0.4);
   border-radius: 50%;
   cursor: pointer;
   transition: background 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.nav__dot li.current {
   background: rgb(254, 121, 108, 0.9);
   transition: background 0.1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.nav__dot li:last-child {
   margin: 0;
}

.nav__list {
   width: 100%;
   padding-top: 12px;
   text-align: center;
   padding-bottom: 12px;
   background-color: rgb(228, 235, 253);
}

.nav-list {
   height: 16px;
   width: 90%;
   text-align: center;
   height: 25px;
   border-radius: 7px;
}

.nav-list:hover {
   height: 16px;
   width: 90%;
   text-align: center;
   height: 25px;
   border-radius: 7px;
   background-color: rgb(202, 208, 224);
}

@media only screen and (min-width: 1080px) {
   .nav-list {
      display: none;
   }
}
@media only screen and (min-width: 1080px) {
   .nav__list {
      display: none;
   }
}
.normal-link {
   color: black;
}

.normal-link:hover {
   color: red;
}

.partnership-img {
   height: 100%;
   width: 100%;
   padding-right: 50px;
}

.pc-visit-logo {
   height: 26px;
   width: 26px;
}

.popover-menu {
   margin-top: 66px;
}

.popover-list-button{
   color: black;
   border-color: transparent;
   background-color: transparent;
}
.popover-list-button{
   color: black;
   border-color: transparent;
   background-color: transparent;
}

.teamviewer-logo {
   height: 26px;
   width: 26px;
}

.qs_link {
   position: relative;
}

.Resizer.vertical {
   width: 10%;
}

.slide-container {
   position: center;
   width: 50%;
}

.sys-admin-img {
   height: 100%;
   width: 100%;
}

.approved-img {
   height: 50%;
   width: 50%;
}

.offering-img {
   height: 82%;
   width: 82%;
}

.nav__dot {
   list-style-type: none;
}