
.react-multi-carousel-list{display:flex;align-items:center;overflow:hidden;position:relative}
.react-multi-carousel-track{list-style:none;padding:0;margin:0;display:flex;flex-direction:row;position:relative;transform-style:preserve-3d;backface-visibility:hidden;will-change:transform,transition}
.react-multi-carousel-dot-list{position:absolute;bottom:0;display:flex;left:0;right:0;justify-content:center;margin:auto;padding-top:1%;margin:4px;list-style:none;text-align:center}
.react-multi-carousel-dot button{display:inline-block;width:15px;height:15px;border-radius:50%;opacity:1;padding:5px 5px 5px 5px;box-shadow:none;transition:background .5s;border-width:2px;border-style:solid;border-color:rgb(254, 121, 108, 0.9);padding:0;margin:0;margin-right:6px;outline:0;cursor:pointer}
.react-multi-carousel-dot button:hover:active{background:rgb(254, 121, 108, 0.9)}
.react-multi-carousel-dot--active button{background:rgb(254, 121, 108, 0.9)}
.react-multi-carousel-item{transform-style:preserve-3d;backface-visibility:hidden}
@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){.react-multi-carousel-item{flex-shrink:0 !important}.react-multi-carousel-track{overflow:visible !important}}
.arrow {
  position: absolute;
  top: 40%;
}

.right {
  right: 0;
  padding-right: 0.5%;
}

.left {
  left: 0;
  padding-left: 0.5%;
}

.footer-master {
  background-image: url('../icons/circuit-board.svg');
}